import http from '@/utils/http.js'

// 手机号登录
export function phoneLogin(data) {
    return http({
        url: "/jasoboss/admin/login",
        method: "post",
        data
    })
}

// 发送验证码
export function sendCode(data) {
    return http({
        url: "/jasoboss/admin/sms/code",
        method: "post",
        data
    })
}

// 二维码登录
export function QRCode(data) {
    return http({
        url: "/jasoboss/admin/weixin/login",
        method: "post",
        data
    })
}
// 自建应用登录
export function agentLogin(data) {
    return http({
        url: "/jasoboss/admin/agent/login",
        method: "post",
        data
    })
}


// 支付详情
export function payDetail(data) {
    return http({
        url: "",
        method: "post",
        data
    })
}