import http from '@/utils/http.js'

// 获取菜单
export function menuList(data) {
    return http({
        url: "/jasoboss/menu/list",
        method: "post",
        data
    })
}

