<template>
    <div class="login">
        <div class="form">
            <div class="mode-tab">
                <p
                    @click="onTab(2)"
                    v-show="baseUrl == 'http://192.168.1.25:8021/'"
                    :class="tab == 2 ? 'tab-color' : ''"
                >
                    <span class="text">
                        企业微信登录
                        <span class="bot"></span>
                    </span>
                </p>
                <p
                    v-show="baseUrl == 'http://192.168.1.25:8021/'"
                    :class="tab == 1 ? 'tab-color' : ''"
                    @click="onTab(1)"
                >
                    <span class="text">
                        手机号登录
                        <span class="bot"></span>
                    </span>
                </p>
            </div>
            <div v-if="tab == 1">
                <div class="phone">
                    <p>手机号</p>
                    <el-input
                        placeholder="请输入手机号"
                        v-model="phone"
                        clearable
                        style="width: 350px"
                    >
                    </el-input>
                </div>
                <div class="code">
                    <p>验证码</p>
                    <p>
                        <el-input
                            placeholder="请输入验证码"
                            v-model="code"
                            clearable
                            style="width: 274px"
                        >
                        </el-input>
                        <button
                            :disabled="show"
                            class="getCode"
                            @click="onGetCode()"
                        >
                            {{ butText }}
                        </button>
                    </p>
                </div>
                <button class="Submit" @click="onCodeLogin">登录</button>
            </div>
            <div v-if="tab == 2">
                <div class="ewm">
                    <iframe
                        name="child"
                        id="child"
                        v-bind:src="reportUrl"
                        width="auto"
                        height="100%"
                        frameborder="0"
                        scrolling="no"
                        style="padding-top: 1rem"
                    >
                    </iframe>
                </div>
            </div>
        </div>
        <div @click="goLink" class="filings">备案号：浙ICP备14025588号-3</div>
    </div>
</template>
<script>
import { sendCode, phoneLogin, QRCode, agentLogin } from '@/api/login';
import { menuList } from '@/api/menu';

export default {
    data() {
        return {
            tab: 2,
            butText: '获取验证码',
            show: false,
            phone: '',
            code: '',
            breadcrumb: [],
            baseUrl: this.$baseUrl,
            reportUrl:
                'https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=ww08d1e1b5493e5773&agentid=1000216&redirect_uri=http://www.ijasoso.com&state=STATE',
        };
    },
    mounted() {
        window.addEventListener('keydown', this.handleKeyDown, true);
        if (this.$route.query.code) {
            let data = {
                param: {
                    code: this.$route.query.code,
                },
            };
            // if (localStorage.getItem("menuData")) {
            //   return this.$message.error("请先退出已登录的账号");
            // }
            localStorage.clear();
            agentLogin(data).then((res) => {
                if (res.code == 200) {
                    sessionStorage.setItem(
                        'accessToken',
                        res.data.token.accessToken
                    );
                    sessionStorage.setItem(
                        'nickName',
                        res.data.adminDO.nickName
                    );
                    sessionStorage.setItem('adminId', res.data.adminDO.id);
                    res.data.adminDO.departmentAdminDOList.forEach((item) => {
                        if (item.main == 1) {
                            sessionStorage.setItem(
                                'departmentId',
                                item.departmentId
                            );
                            sessionStorage.setItem(
                                'departmentName',
                                item.departmentName
                            );
                        }
                    });
                    sessionStorage.setItem(
                        'departmentAdminDOList',
                        JSON.stringify(res.data.adminDO.departmentAdminDOList)
                    );
                    sessionStorage.setItem(
                        'userType',
                        res.data.adminDO.roleDO.type
                    );
                    if (res.data.adminDO.roleDO.type == 2) {
                        sessionStorage.setItem('category', 2);
                    } else if (res.data.adminDO.roleDO.type == 3) {
                        sessionStorage.setItem('category', 3);
                    }
                    this.$message.success('登录成功');
                    // this.$message({
                    //     message: '登录成功',
                    //     type: 'success',
                    // });
                    if (res.data.adminDO.type == 2) {
                        this.$router.push('/adminframe');
                    } else if (res.data.adminDO.type == 3) {
                        let data = {
                            param: {
                                isLogin: 1,
                            },
                        };
                        menuList(data).then((res) => {
                            this.opetion(res);
                        });
                    }
                }
            });
        }
        if (this.$route.query.auth_code) {
            let data = {
                param: {
                    authCode: this.$route.query.auth_code,
                },
            };
            // if (localStorage.getItem("menuData")) {
            //   return this.$message.error("请先退出已登录的账号");
            // }
            localStorage.clear();
            QRCode(data).then((res) => {
                if (res.code == 200) {
                    sessionStorage.setItem(
                        'accessToken',
                        res.data.token.accessToken
                    );
                    sessionStorage.setItem(
                        'nickName',
                        res.data.adminDO.nickName
                    );
                    sessionStorage.setItem('adminId', res.data.adminDO.id);
                    res.data.adminDO.departmentAdminDOList.forEach((item) => {
                        if (item.main == 1) {
                            sessionStorage.setItem(
                                'departmentId',
                                item.departmentId
                            );
                            sessionStorage.setItem(
                                'departmentName',
                                item.departmentName
                            );
                        }
                    });
                    sessionStorage.setItem(
                        'departmentAdminDOList',
                        JSON.stringify(res.data.adminDO.departmentAdminDOList)
                    );
                    sessionStorage.setItem(
                        'userType',
                        res.data.adminDO.roleDO.type
                    );
                    if (res.data.adminDO.roleDO.type == 2) {
                        sessionStorage.setItem('category', 2);
                    } else if (res.data.adminDO.roleDO.type == 3) {
                        sessionStorage.setItem('category', 3);
                    }
                    this.$message.success('登录成功');
                    // this.$message({
                    //     message: '登录成功',
                    //     type: 'success',
                    // });
                    if (res.data.adminDO.type == 2) {
                        this.$router.push('/adminframe');
                    } else if (res.data.adminDO.type == 3) {
                        let data = {
                            param: {
                                isLogin: 1,
                            },
                        };
                        menuList(data).then((res) => {
                            this.opetion(res);
                        });
                    }
                }
            });
        }
    },
    methods: {
        goLink() {
            window.location.href = 'https://beian.miit.gov.cn/';
        },
        opetion(res) {
            let menuData = res.data;
            let routeList = this.$Tree.treeDataTranslate(
                res.data,
                'id',
                'parentId'
            );

            let menuArr = this.$Tree.treeDataTranslateAudit(
                this.$Tree.childrenData(menuData),
                'id',
                'parentId'
            );
            let item = '';
            if (routeList[0].children && routeList[0].children.length > 0) {
                if (routeList[0].children[0].children) {
                    item = routeList[0].children[0].children[0];
                }
                item = routeList[0].children[0];
            } else {
                item = routeList[0];
            }
            let isButArr = [];
            for (let i in menuArr) {
                if (menuArr[i].type == 2) {
                    if (menuArr[i].parentId == item.id) {
                        isButArr.push(menuArr[i]);
                    }
                }
            }
            sessionStorage.setItem('isButArr', JSON.stringify(isButArr));
            this.$router.push('/' + item.route);
            // if (item.children) {
            //     this.$router.push('/' + item.children[0].route);
            // } else {
            //     this.$router.push('/' + item.children[0].route);
            // }
            sessionStorage.setItem('nav', JSON.stringify(routeList[0]));
        },
        link() {
            window.location.href =
                'https://open.work.weixin.qq.com/wwopen/sso/qrConnect?appid=ww08d1e1b5493e5773&agentid=1000216&redirect_uri=http://www.ijasoso.com&state=STATE';
        },
        onTab(i) {
            this.tab = i;
        },
        onGetCode() {
            // if (localStorage.getItem("menuData")) {
            //   return this.$message.error("请先退出已登录的账号");
            // }
            let patrn = /^1[3456789]\d{9}$/;
            if (this.phone == '') {
                this.$message.error('请输入手机号');
            } else if (!patrn.test(this.phone)) {
                this.$message.error('请输入正确手机号');
            } else {
                let data = {
                    param: {
                        userName: this.phone,
                    },
                };

                sendCode(data).then((res) => {
                    if (res.code == 200) {
                        this.$message({
                            message: '发送成功,请注意查收',
                        });
                        this.countDown();
                    }
                });
            }
        },
        countDown() {
            const time_coumt = 60;
            if (!this.timer) {
                this.butText = time_coumt;
                this.show = true;
                this.timer = setInterval(() => {
                    if (this.butText > 0 && this.butText <= time_coumt) {
                        this.butText--;
                    } else {
                        this.butText = '重新发送';
                        this.show = false;
                        clearInterval(this.timer);
                        this.timer = null;
                    }
                }, 1000);
            }
        }, // 登录
        onCodeLogin() {
            // if (localStorage.getItem("menuData")) {
            //   return this.$message.error("请先退出已登录的账号");
            // }

            let patrn = /^1[3456789]\d{9}$/;
            if (this.phone == '') {
                this.$message.error('请输入手机号');
            } else if (!patrn.test(this.phone)) {
                this.$message.error('请输入正确手机号');
            } else if (this.code == '') {
                this.$message.error('请输入验证码');
            } else {
                let data = {
                    param: {
                        userName: this.phone,
                        code: this.code,
                    },
                };
                localStorage.clear();
                phoneLogin(data).then((res) => {
                    // console.log(res);

                    if (res.code == 200) {
                        // this.$message.success('登录成功');
                        sessionStorage.setItem(
                            'accessToken',
                            res.data.token.accessToken
                        );
                        sessionStorage.setItem(
                            'nickName',
                            res.data.adminDO.nickName
                        );
                        sessionStorage.setItem('adminId', res.data.adminDO.id);
                        res.data.adminDO.departmentAdminDOList.forEach(
                            (item) => {
                                if (item.main == 1) {
                                    sessionStorage.setItem(
                                        'departmentId',
                                        item.departmentId
                                    );
                                    sessionStorage.setItem(
                                        'departmentName',
                                        item.departmentName
                                    );
                                }
                            }
                        );
                        sessionStorage.setItem(
                            'departmentAdminDOList',
                            JSON.stringify(
                                res.data.adminDO.departmentAdminDOList
                            )
                        );
                        sessionStorage.setItem(
                            'userType',
                            res.data.adminDO.roleDO.type
                        );
                        sessionStorage.setItem(
                            'brandType',
                            res.data.adminDO.brand
                        );
                        if (res.data.adminDO.roleDO.type == 2) {
                            sessionStorage.setItem('category', 2);
                        } else if (res.data.adminDO.roleDO.type == 3) {
                            sessionStorage.setItem('category', 3);
                        }
                        this.$message({
                            message: '登录成功',
                            type: 'success',
                        });
                        if (res.data.adminDO.type == 2) {
                            this.$router.push('/adminframe');
                        } else if (res.data.adminDO.type == 3) {
                            let data = {
                                param: {
                                    isLogin: 1,
                                },
                            };
                            menuList(data).then((res) => {
                                // localStorage.setItem("menuData", JSON.stringify(res.data));
                                this.opetion(res);
                            });
                        }
                    } else if (res.code == 417) {
                        this.$message.error('请输入正确验证码');
                    } else if (res.code == 400) {
                        this.$message.error('登陆失败');
                    }
                });
            }
        },
        // 回车键事件
        handleKeyDown(e) {
            let key = null;
            if (window.event === undefined) {
                key = e.keyCode;
            } else {
                key = window.event.keyCode;
            }
            if (key === 13) {
                //触发的事件
                this.onCodeLogin();
            }
        },
    },
    destroyed() {
        window.removeEventListener('keydown', this.handleKeyDown, true);
    },
    watch: {
        $route(to, from) {
            if (this.$route.query.auth_code) {
                let data = {
                    param: {
                        authCode: this.$route.query.auth_code,
                    },
                };
                // if (localStorage.getItem("menuData")) {
                //   return this.$message.error("请先退出已登录的账号");
                // }
                localStorage.clear();
                QRCode(data).then((res) => {
                    if (res.code == 200) {
                        sessionStorage.setItem(
                            'accessToken',
                            res.data.token.accessToken
                        );
                        sessionStorage.setItem(
                            'nickName',
                            res.data.adminDO.nickName
                        );
                        sessionStorage.setItem('adminId', res.data.adminDO.id);
                        res.data.adminDO.departmentAdminDOList.forEach(
                            (item) => {
                                if (item.main == 1) {
                                    sessionStorage.setItem(
                                        'departmentId',
                                        item.departmentId
                                    );
                                    sessionStorage.setItem(
                                        'departmentName',
                                        item.departmentName
                                    );
                                }
                            }
                        );
                        sessionStorage.setItem(
                            'departmentAdminDOList',
                            JSON.stringify(
                                res.data.adminDO.departmentAdminDOList
                            )
                        );
                        sessionStorage.setItem(
                            'userType',
                            res.data.adminDO.roleDO.type
                        );
                        if (res.data.adminDO.roleDO.type == 2) {
                            sessionStorage.setItem('category', 2);
                        } else if (res.data.adminDO.roleDO.type == 3) {
                            sessionStorage.setItem('category', 3);
                        }
                        this.$message.success('登录成功');
                        // this.$message({
                        //     message: '登录成功',
                        //     type: 'success',
                        // });
                        if (res.data.adminDO.type == 2) {
                            this.$router.push('/adminframe');
                        } else if (res.data.adminDO.type == 3) {
                            let data = {
                                param: {
                                    isLogin: 1,
                                },
                            };
                            menuList(data).then((res) => {
                                this.opetion(res);
                            });
                        }
                    }
                });
            }
        },
        deep: true,
    },
};
</script>
<style lang="scss" scoped>
/deep/ .el-input__inner::-webkit-input-placeholder,
/deep/ .el-range-input::-webkit-input-placeholder {
    /* WebKit browsers 适配谷歌 */
    font-size: 12px !important;
}
.login {
    width: 100%;
    height: 100%;
    background: url('../../assets/login.png') no-repeat top center;
    background-size: cover;
    position: relative;
    .form {
        width: 470px;
        height: 534px;
        background: #ffffff;
        border-radius: 8px;
        border: 1px solid #979797;
        position: absolute;
        right: 7%;
        top: 25%;
        .mode-tab {
            font-size: 20px;
            font-weight: 500;
            color: #333333;
            line-height: 28px;
            display: flex;
            padding-top: 47px;
            p {
                flex: 1;
                text-align: center;
                cursor: pointer;
            }
            .tab-color {
                font-size: 25px;
                font-weight: 500;
                color: #2370eb;
                .text {
                    position: relative;
                    width: auto;
                }
                .bot {
                    display: block;
                    width: 24px;
                    height: 3px;
                    background: #2370eb;
                    border-radius: 4px;
                    bottom: -7px;
                    position: absolute;
                    left: 2px;
                }
            }
        }
        .phone {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            line-height: 25px;
            padding-left: 50px;
            p:nth-child(1) {
                padding: 70px 0 10px 0;
            }
        }
        .code {
            font-size: 18px;
            font-weight: 400;
            color: #333333;
            line-height: 25px;
            padding-left: 50px;
            p:nth-child(1) {
                padding: 28px 0 10px 0;
            }
            p:nth-child(2) {
                display: flex;
                align-items: center;
            }
        }
        .getCode {
            width: 74px;
            height: 40px;
            background: #2370eb;
            border-radius: 2px;
            border: none;
            cursor: pointer;
            font-size: 13px;
            font-weight: 400;
            color: #ffffff;
        }
        .Submit {
            width: 290px;
            height: 40px;
            display: block;
            background: #2370eb;
            border-radius: 2px;
            border: none;
            outline: none;
            margin: 0 auto;
            margin-top: 77px;
            font-size: 18px;
            font-weight: 400;
            color: #ffffff;
            cursor: pointer;
        }
    }
    .filings {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        color: #fff;
        cursor: pointer;
    }
    .ewm {
        height: 450px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
    }
}
</style>
